<template>
    <div>
        <top>
            <div class="tc cfff">
                <div class="fs42 pt140">关于我们</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">有关安迩平台关于我们</div>
            </div>
        </top>
        <div class="rowjc" style="margin-top: -130px; ">
            <div class="box" style="padding: 0px 40px; min-height: calc(100vh - 670px ); ">
                <div class="fs28 c333 pt30 fwb tc">关于我们</div>
                
                <p class="mt36 pb30 lh26 c666">
                    本网站上可阅读和可见的所有资料都受到知识产权法的保护，本网站和本公司拥有本网站相关网页内所有资料的版权，或与本网站相关网页内的资料提供者共同拥有该网页内资料的版权所有，此等资料都是受到著作权法和版权法的法律保护。未经本公司或本网站的明确书面许可，对于本网站上的任何内容，任何机构或个人不得擅自任何人不得对其进行复制或在非本公司所属的服务器上做镜像，或将其用作商业用途，任何机构或个人没有获得本网站或各自的版权所有者明确的书面同意下，不得分发、修改、散布、再使用、再传递或使用本网站的内容用于任何公众商业用途。

                    本网站LOGO商标或标识都是本公司的商品及服务商标和商号。所有其他本网站没有主张权利的商标和产品名称则可能是它们各自所有权人的商标或注册商标。未获得本公司或其他在本网站上有权使用商标的第三方的书面同意和授权，不得使用或展示本网站所刊载之任何商标。
                </p>
            </div>
        </div>
        <div style="height:60px;"></div>
       
        <bottom ></bottom>
       
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            bottom, top
        },
        data() {
            return {

            }
        },
        created() {

        },
        mounted() {
            console.log(this.$route.query.id)
        },
        methods: {

        },
    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 20px;

    }

    .bottomQ:not(:last-child) {
        border-bottom: 1px dashed #D7D7D7;
    }

    .bottomQ:hover {
        cursor: pointer;
    }
</style>